import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import spewakLogoSVG from '../images/svg/spewak-logo.svg';
import { CONTENT_WIDTH, SHOW_CONTACT } from '../functions/global';

const NavLink = styled(Link)`
  color: #222;
  font-size: 1rem;
  font-weight: ${props => props.fontWeight || "normal"};
  line-height: 1;
  margin: 0 1.5rem 0 0;
  padding: 0.25rem;
  text-decoration: none;
  display: ${props => props.display || "inline"};
  align-items: flex-start;

  &.current-page {
    border-bottom: 2px solid #222;
  }

  &:last-of-type {
    margin-right: 0;
  }

  .name {
    padding-left: 10px;
    padding-top: 10px;
    flex: 1;
  }
`;

const Header = () => {
  return (
    <header
      css={ css`
        display: flex;
        justify-content: space-between;
        padding: 0.5rem calc( (100vw - ${CONTENT_WIDTH}px - 0.5rem) / 2 );
      `}
    >
      {/* site name */}
      <NavLink to="/" fontWeight="bold" display="flex">
        <div id="jon-spewak-logo">
          <img src={ spewakLogoSVG }
            width={ 75 } height={ 75 }
          />
        </div>
      </NavLink>
      {/* navigation */}
      { ( SHOW_CONTACT &&
        <nav css={css`
          margin-top: 0;
        `}>
          <NavLink to="/" activeClassName="current-page">Home</NavLink>
          {/* <NavLink to="/blog" activeClassName="current-page">Blog</NavLink> */}
          <NavLink to="/contact" activeClassName="current-page">Contact</NavLink>
        </nav>
      ) }
    </header>
  );
}

export default Header;
