import React from 'react';
import { Global, css } from '@emotion/react';
import Header from './header';
import { CONTENT_WIDTH } from '../functions/global';
// import useSiteMetadata from '../hooks/use-sitemetadata';

const Layout = ({ children }) => {
  // const { title, description } = useSiteMetadata();

  return (
    <>
      <Global styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
        }
        * + * {
          ${'' /* margin-top: 1rem; */}
        }
        html, body {
          margin: 0;
          ${'' /* color: #555; */}
          color: #000000;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
          font-size: 18px;
          line-height: 1.4;
        }
        /* remove margin for main div that gatsby mounts into: */
        > div {
          margin-top: 0;
        }
        h1, h2, h3, h4, h5, h6 {
          color: #000000;
          line-height: 1.1;

          + * {
            margin-top: 0.5rem;
          }
        }
        strong {
          color: #000000;
        }
        li {
          margin-top: 0.25rem;
        }
        .Table {
          display: table;
        }
        .TableBody {
          display: table-row-group;
        }
        .TableRow {
          display: table-row;
        }
        .TableCell {
          display: table-cell;
        }
        .no-select {
          -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
             -khtml-user-select: none; /* Konqueror HTML */
               -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                          supported by Chrome, Edge, Opera and Firefox */
        }
      `}/>
      <div
      >
        <Header />
        <main
          css={css`
            ${'' /* margin: 0rem auto 0rem;
            max-width: 90vw;
            width: ${CONTENT_WIDTH}px; */}
          `}
        >
          { children }
        </main>
      </div>
    </>
  );

}

export default Layout;
